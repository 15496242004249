import { Suspense, useEffect, lazy, useCallback, useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import { Outlet } from 'react-router-dom';
import { userSelector } from 'redux/selectores';
import { useAppSelector } from 'redux/store';
import useToggle from 'hooks/useToggle';
import { getBoolean } from 'utils/getBoolean';
import { ToastContainer } from 'react-toastify';
import { loading } from 'components/SuspenseLoading';
import { weikUsers } from 'constants/users';
import 'react-toastify/ReactToastify.min.css';
import packageJson from '../../package.json';
import HeaderStatus from 'components/HeaderStatus';

const Topbar = lazy(() => import('./Topbar'));
const LeftSidebar = lazy(() => import('./LeftSidebar'));
const Footer = lazy(() => import('./Footer'));

const DetachedLayout = () => {
    const [isMenuOpened, toggleMenu] = useToggle();
    const { user } = useAppSelector(userSelector);

    useEffect(() => {
        document.body.setAttribute('data-layout', 'detached');
        document.body.setAttribute('data-layout-mode', 'fluid');
    }, []);

    const openMenu = useCallback(() => {
        toggleMenu();
        if (document.body) {
            if (isMenuOpened) document.body.classList.add('sidebar-enable');
            else document.body.classList.remove('sidebar-enable');
        }
    }, [isMenuOpened, toggleMenu]);

    const isTestingEnv: boolean = useMemo(() => {
        return getBoolean(process.env.REACT_APP_ISTESTING ?? 'false');
    }, []);

    return (
        <>
            {isTestingEnv && (
                <div className="alert alert-info position-absolute" role="alert" style={{ zIndex: '9999' }}>
                    TEST ENV - {packageJson.version}
                </div>
            )}
            <Suspense fallback={loading()}>
                <Topbar openLeftMenuCallBack={openMenu} />
            </Suspense>
            <Container fluid>
                <div className="wrapper">
                    <Suspense fallback={loading()}>
                        <LeftSidebar hideUserProfile={false} />
                    </Suspense>
                    <div className="content-page">
                        <div className="content">
                            {weikUsers.includes(user.role) && (
                                <Suspense fallback={loading()}>
                                    <HeaderStatus />
                                </Suspense>
                            )}
                            <Outlet />
                            <ToastContainer
                                position="top-right"
                                autoClose={1500}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />
                        </div>
                        <Suspense fallback={loading()}>
                            <Footer />
                        </Suspense>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default DetachedLayout;
