import { EmployeeData } from '@models/user';
import { WEIKSTUDIO_DOMAIN } from 'constants/domains';
import { MODAL_OPEN_LOCAL_STORAGE_KEY, WE_LOCAL_STORAGE_KEY, WT_LOCAL_STORAGE_KEY } from 'constants/horario';
import { DEFAULT_PASSWORD } from 'constants/users';

/**
 * Devuelve si el valor de un propiedad es diferente a undefined o vacio ''
 * @param {any} object Objeto con la propiedad a evaluar
 * @param {string} property propiedad a evaluar
 * @returns {boolean}
 */
export const checkIfValuePropertyExist = (object: any, property: string): boolean => {
    const value = object[property];
    if (!value) return false; // si fecha es '' o undefined
    return true;
};

/**
 * Elimina los valores correspondientes a la gestion del horario de la local storage
 */
export const clearLocalStorageItems = (): void => {
    const keys = [WT_LOCAL_STORAGE_KEY, WE_LOCAL_STORAGE_KEY, MODAL_OPEN_LOCAL_STORAGE_KEY];
    keys.forEach((key) => {
        localStorage.removeItem(key);
    });
};

/**
 * Devuelve un objeto con el cliente del usuario
 * @param {any[]} clients Cliente
 * @param {string} domain Dominio
 */
export const findClient = (
    clients: any[],
    domain: string = WEIKSTUDIO_DOMAIN
): { nombre: string; clientImage: string; brandingUrl: string } => {
    const clientObj = {
        nombre: '',
        clientImage: '',
        brandingUrl: '',
    };
    clients.forEach((doc: any) => {
        const data = { ...doc.data() };
        if (data.domain.includes(domain)) {
            clientObj.nombre = data.nombre;
            clientObj.clientImage = data.logo;
            clientObj.brandingUrl = data.branding;
        }
    });
    return clientObj;
};

/**
 * Devuelve El dominio sin ., y sin @ en dado caso que los contenga y en minuscula
 */
export const removeTrashFromDomain = (domainToBeCleaned: string): string => {
    let newDomain: string = domainToBeCleaned;
    if (domainToBeCleaned.includes('@') && domainToBeCleaned.includes('.')) {
        newDomain = domainToBeCleaned.split('@')[1].split('.')[0];
    }
    return newDomain;
};

/**
 * Devuelve username del email si este contiene @ y .
 */
export const getUserNameFromEmail = (emailUser: string): string => {
    let newUserName: string = emailUser;
    if (emailUser.includes('@') && emailUser.includes('.')) newUserName = emailUser.split('@')[0];
    return newUserName;
};

/**
 * Devuelve La contraseña
 */
export const getPassword = (password: string): string => {
    let newPasswrod: string = password;
    if (!password) newPasswrod = DEFAULT_PASSWORD;
    return newPasswrod;
};

/**
 * Devuelve el email del usuario
 */
export const getEmail = (userName: string, domain: string): string => {
    return `${userName}@${domain}.com`.toLowerCase();
};

/**
 * Devuelve la hora en formato 02:34 PM
 */
export const getColombianTime = (value: string): string => {
    if (value) {
        const hourTime: number = +value.split(':')[0];
        const timeMoment: 'AM' | 'PM' = hourTime >= 12 ? 'PM' : 'AM';
        const hour: string = hourTime > 12 ? `${hourTime - 12}` : `${hourTime}`;
        return `${hour}:${value.split(':')[1]} ${timeMoment}`;
    }
    return '00:00 AM';
};

/**
 * Revisa que los correos sean únicos en el arreglo de employees existente
 */
export const isUniqueEmail = (newUserEmail: string, employes: EmployeeData[]): boolean =>
    !!!employes.find(({ email }) => newUserEmail === email);
