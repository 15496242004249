import { LoaderProps } from '@models/components/loader';
import Card from 'react-bootstrap/Card';

const Loader = ({ isLoading, children }: LoaderProps) => {
    return (
        <div className="wrapper-loader">
            {isLoading && (
                <div className="loader">
                    <Card />
                </div>
            )}
            {children}
        </div>
    );
};

export default Loader;
