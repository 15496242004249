import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CurrentClientItem } from '@models/clients';
import { CURRENT_CLIENT_NAME, LOCAL_STORAGE_CLIENT } from 'constants/redux';

const clientString = localStorage.getItem(LOCAL_STORAGE_CLIENT);
const clientObj: CurrentClientItem = clientString
    ? JSON.parse(clientString)
    : {
          nombre: '',
          domain: '',
          logo: '',
          branding: '',
      };

const initialState: CurrentClientItem = { ...clientObj };

export const currentClientSlice = createSlice({
    name: CURRENT_CLIENT_NAME,
    initialState,
    reducers: {
        setCurrentClient: (_: any, action: PayloadAction<CurrentClientItem>) => {
            return { ...action.payload };
        },
    },
});

export const { setCurrentClient } = currentClientSlice.actions;
export default currentClientSlice.reducer;
