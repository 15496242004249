import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SELECTED_ROWS_NAME } from 'constants/redux';
import { datosAddTypeToBd } from '@models/projects';

const initialState: datosAddTypeToBd[] | {}[] = [];

export const selectedRowsSlice = createSlice({
    name: SELECTED_ROWS_NAME,
    initialState,
    reducers: {
        setSelectedRows: (_: any, action: PayloadAction<datosAddTypeToBd[]>): any => {
            const newSelectedRow = [...action.payload];
            return [...newSelectedRow];
        },
    },
});

export const { setSelectedRows } = selectedRowsSlice.actions;
export default selectedRowsSlice.reducer;
