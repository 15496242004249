import { ErrorBoundaryProps, ErrorBoundaryState } from '@models/src/errorBoundary';
import React from 'react';
import { Button, Card, Container } from 'react-bootstrap';

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            error: null,
        };
    }

    static getDerivedStateFromError(error: Error) {
        return {
            error,
        };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        const { recordError } = this.props;
        console.log(`🤬 ~ Error - [${new Date()}]`, error, errorInfo);
        recordError(error);
    }

    render(): React.ReactNode {
        if (this.state.error !== null) {
            return (
                <Container>
                    <Card>
                        <Card.Body>
                            <h1>Ups Ocurrio un error</h1>
                            <Button className="btn btn-info" onClick={() => (window.location.href = '/')}>
                                Regresar
                            </Button>
                        </Card.Body>
                    </Card>
                </Container>
            );
        }
        return this.props.children;
    }
}
