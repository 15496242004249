/* Default layout screens No Login Yet*/
export const ACCOUNT_ROUTE = 'account';
export const LOGIN_ROUTE = 'login';
export const FORGET_PASSWORD_ROUTE = 'forget-password';
/* Detached layout screens Login members*/
export const CLIENTS_ID_ROUTE = ':client';
export const DESIGNERS_ROUTE = 'designers';
export const CALENDARIO_ROUTE = 'calendario';
export const SOLICITUDES_ROUTE = 'solicitudes';
export const NOTICIAS_ROUTE = 'noticias';
export const PLATAFORMA_ROUTE = 'plataforma';
export const AGREGAR_CLIENTE_ROUTE = 'agregar_cliente';
export const PPTS_ROUTE = 'ppts';
export const MOTIONS_ROUTE = 'motions';
export const REPORTES_PPTS_ROUTE = 'reportes_ppts';
export const REPORTES_MOTIONS_ROUTE = 'reportes_motions';
/* Not found Screens */
export const NOT_FOUND_ROUTE = '/not_found';
