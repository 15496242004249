import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UtilidadesData } from '@models/utilidades';
import { UTILIDADES_NAME } from 'constants/redux';

const initialState: UtilidadesData = { brandingUrl: '', excelUrl: '' };

export const utilidadesSlice = createSlice({
    name: UTILIDADES_NAME,
    initialState,
    reducers: {
        setBrandingUrl: (state: UtilidadesData, action: PayloadAction<string>) => {
            return { ...state, brandingUrl: action.payload };
        },
        setExcelUrl: (state: UtilidadesData, action: PayloadAction<string>) => {
            return { ...state, excelUrl: action.payload };
        },
        resetUtilidades: () => {
            const newInitialState: UtilidadesData = { brandingUrl: '', excelUrl: '' };
            return { ...newInitialState };
        },
    },
});

export const { setBrandingUrl, setExcelUrl, resetUtilidades } = utilidadesSlice.actions;
export default utilidadesSlice.reducer;
