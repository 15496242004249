import awsRum from 'aws';
import { useCallback } from 'react';
import { useAppSelector } from 'redux/store';
import { userSelector } from 'redux/selectores';

export default function useRecordError() {
    const { user } = useAppSelector(userSelector);

    const recordError = useCallback(
        (eventErrorFrom: string, error: any) => {
            const errorToBePassed: any = {
                eventErrorFrom,
                error,
                user: user.email,
                fecha: new Date().toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: '2-digit' }),
                hora: new Date().toLocaleTimeString('es', { hour: 'numeric', minute: 'numeric', second: 'numeric' })
            }
            awsRum.recordError(errorToBePassed);
        },
        [user.email]
    );

    return {
        recordError,
    };
}
