import { db } from 'database';
import { collection, getDocs } from 'firebase/firestore';
import { ClientItem } from '@models/clients';
import { useDispatch } from 'react-redux';
import { setClients } from 'redux/clients';
import { setEncript } from 'utils/getEncript';
import { CLIENTS_PATH } from 'constants/paths';
import { useCallback } from 'react';
import { SESSION_STORAGE_CLIENTS } from 'constants/redux';
import { WEIKSTUDIO_DOMAIN } from 'constants/domains';
import useRecordError from 'hooks/aws/useRecordError';
import { WEIK_CUSTOM_ERROR } from 'constants/aws';

export default function useGetClients() {
    const { recordError } = useRecordError();
    const dispatch = useDispatch();
    const getClients = useCallback(async (): Promise<void> => {
        const childrenObjClients: ClientItem[] = [];
        try {
            const queryDocs = await getDocs(collection(db, CLIENTS_PATH));
            queryDocs.forEach((doc) => {
                const { nombre, domain, branding, logo } = doc.data();
                if (domain !== WEIKSTUDIO_DOMAIN) childrenObjClients.push({ nombre, domain, branding, logo });
            });
            const ciphertext = setEncript(
                JSON.stringify(childrenObjClients),
                process.env.REACT_APP_ENCRYPTED_PASSWORD ?? ''
            );
            sessionStorage.setItem(SESSION_STORAGE_CLIENTS, ciphertext);
            dispatch(setClients(childrenObjClients));
        } catch (error) {
            recordError(WEIK_CUSTOM_ERROR + "_getClients", error);
        }
    }, [dispatch, recordError]);
    return {
        getClients,
    };
}
