import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PayLoadUserData, UserData } from '@models/user';
import { LOCAL_STORAGE_CLIENT, USER_NAME } from 'constants/redux';

const userFromSessionStorageString = sessionStorage.getItem(USER_NAME) ?? '';
const userFromSessionStorageObject: UserData = userFromSessionStorageString
    ? JSON.parse(userFromSessionStorageString)
    : {};

const isLoggedIn = sessionStorage.getItem(USER_NAME) ? true : false;
let client: string | null = localStorage.getItem(LOCAL_STORAGE_CLIENT) ?? '';

if (!client.includes('{') && !client.includes('}')) {
    localStorage.removeItem(LOCAL_STORAGE_CLIENT);
    client = null;
} else client = JSON.parse(client).domain;

const initialState: PayLoadUserData = {
    user: userFromSessionStorageObject,
    domain: client,
    isUserLoggedIn: isLoggedIn,
};

export const userSlice = createSlice({
    name: USER_NAME,
    initialState,
    reducers: {
        setId: (state: PayLoadUserData, action: PayloadAction<string>) => {
            const newId = action.payload;
            const user: UserData = {
                ...state.user,
                id: newId,
            };
            return {
                ...state,
                user,
            };
        },
        updateUserExtraHours: (state: PayLoadUserData, action: PayloadAction<UserData>) => {
            const { horasExtra } = action.payload;
            const user: UserData = {
                ...state.user,
                horasExtra,
            };
            sessionStorage.setItem(USER_NAME, JSON.stringify(user));
            return {
                ...state,
                user,
            };
        },
        updateUserWorkingHours: (state: PayLoadUserData, action: PayloadAction<UserData>) => {
            const { horasTrabajo } = action.payload;
            const user: UserData = {
                ...state.user,
                horasTrabajo,
            };
            sessionStorage.setItem(USER_NAME, JSON.stringify(user));
            return {
                ...state,
                user,
            };
        },
        updateUserDocumentNumber: (state: PayLoadUserData, action: PayloadAction<UserData>) => {
            const { numeroDocumento } = action.payload;
            const user: UserData = {
                ...state.user,
                numeroDocumento,
            };
            sessionStorage.setItem(USER_NAME, JSON.stringify(user));
            return {
                ...state,
                user,
            };
        },
        updateDataUser: (state: PayLoadUserData, action: PayloadAction<UserData>) => {
            const { nombreCompleto, userName, fechaNacimiento } = action.payload;
            const user: UserData = {
                ...state.user,
                nombreCompleto,
                userName,
                fechaNacimiento,
            };
            sessionStorage.setItem(USER_NAME, JSON.stringify(user));
            return {
                ...state,
                user,
            };
        },
        updateImageUser: (state: PayLoadUserData, action: PayloadAction<string>) => {
            const userImage = action.payload;
            const user: UserData = {
                ...state.user,
                userImage,
            };
            return {
                ...state,
                user,
            };
        },
        setUser: (state: PayLoadUserData, action: PayloadAction<PayLoadUserData>) => {
            const {
                id,
                uid,
                accessToken,
                email,
                role,
                userImage,
                nombreCompleto,
                userName,
                fechaNacimiento,
                estado,
                horario,
                horasExtra,
                numeroDocumento,
                informacionLaboral,
                ciudadExpedicionDocumento,
                horasTrabajo,
                vacaciones,
            } = action.payload.user;
            const domain = action.payload.domain;
            const isUserLoggedIn = action.payload.isUserLoggedIn;
            const user: UserData = {
                id,
                uid,
                accessToken,
                email,
                role,
                userImage,
                nombreCompleto,
                userName,
                fechaNacimiento,
                estado,
                horario,
                horasExtra,
                horasTrabajo,
                vacaciones,
                numeroDocumento,
                informacionLaboral,
                ciudadExpedicionDocumento,
            };
            return {
                ...state,
                user,
                domain,
                isUserLoggedIn,
            };
        },
        logOutUser: (state: PayLoadUserData) => {
            const domain = null;
            const isUserLoggedIn = false;
            const user: UserData = {};
            sessionStorage.removeItem(USER_NAME);
            localStorage.removeItem(LOCAL_STORAGE_CLIENT);
            return {
                ...state,
                user,
                domain,
                isUserLoggedIn,
            };
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setUser,
    logOutUser,
    setId,
    updateDataUser,
    updateImageUser,
    updateUserDocumentNumber,
    updateUserExtraHours,
    updateUserWorkingHours,
} = userSlice.actions;

export default userSlice.reducer;
