import { SkeletonLoaderProps } from '@models/components/skeletonLoader';
import './styles.scss';

const SkeletonLoader = ({ customClass, width }: SkeletonLoaderProps) => {
    return (
        <div
            style={{ width: width ? width : '100%' }}
            className={`skeleton-container ${customClass ? customClass : ''}`}>
            <div className="skeleton-loader"></div>
        </div>
    );
};

export default SkeletonLoader;
