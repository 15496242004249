import HorarioStatus from 'components/HorarioStatus';
import TeamMembers from 'layouts/OtherMenus/TeamMembers';
import { Card, Col, Row } from 'react-bootstrap';

const HeaderStatus = () => {
    return (
        <Row>
            <Col lg={12} xl={4}>
                <Card>
                    <HorarioStatus />
                </Card>
            </Col>
            <Col lg={12} xl={8}>
                <Card>
                    <TeamMembers />
                </Card>
            </Col>
        </Row>
    );
};

export default HeaderStatus;
