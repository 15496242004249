import { useCallback, useEffect } from 'react';
import { AllRoutes } from 'routes';
import { useAppSelector } from 'redux/store';
import { clientsSelector, employesSelector, userSelector } from 'redux/selectores';
import useHorario from 'hooks/useHorario';
import useGetClients from 'endpoints/useGetClients';
import ErrorBoundary from 'ErrorBoundary';
import useRecordError from 'hooks/aws/useRecordError';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import 'assets/scss/Saas.scss';
import { WEIK_CUSTOM_ERROR, WEIK_CUSTOM_EVENT } from 'constants/aws';
import useRecordEvent from 'hooks/aws/useRecordEvent';

const App = () => {
    const { getClients } = useGetClients();
    const { resetHorario } = useHorario();
    const { user } = useAppSelector(userSelector);
    const employesStore = useAppSelector(employesSelector);
    const clientsStore = useAppSelector(clientsSelector);
    const { recordError } = useRecordError();
    const { recordCustomEvent } = useRecordEvent();

    useEffect(() => {
        if (employesStore.length > 0 && user.id) resetHorario(employesStore, user.id);
    }, [employesStore, resetHorario, user.id]);

    useEffect(() => {
        if (clientsStore.length === 0) {
            getClients();
            recordCustomEvent(WEIK_CUSTOM_EVENT, { from: 'APP - GetClients' });
        }
    }, [clientsStore.length, getClients, recordCustomEvent]);

    const recordErrorHandler = useCallback(
        (error: Error) => {
            recordError(WEIK_CUSTOM_ERROR + '_recordErrorHandler', error);
        },
        [recordError]
    );

    return (
        <ErrorBoundary recordError={recordErrorHandler}>
            <AllRoutes />
        </ErrorBoundary>
    );
};

export default withLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID,
    options: { bootstrap: 'localStorage' },
})(App);
