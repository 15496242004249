import awsRum from 'aws';
import { useCallback } from 'react';
import { useAppSelector } from 'redux/store';
import { userSelector } from 'redux/selectores';

export default function useRecordEvent() {
    const { user } = useAppSelector(userSelector);

    const recordCustomEvent = useCallback(
        (eventType: string, eventData: object) => {
            const event: object = {
                ...eventData,
                user: user.email,
                fecha: new Date().toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: '2-digit' }),
                hora: new Date().toLocaleTimeString('es', { hour: 'numeric', minute: 'numeric', second: 'numeric' })
            }
            awsRum.recordEvent(eventType, event);
        },
        [user.email]
    );

    return {
        recordCustomEvent,
    };
}
