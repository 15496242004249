import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Noticia } from '@models/noticias';
import { NOTICIAS_NAME } from 'constants/redux';

const initialState: Noticia[] = [];

export const noticiasSlice = createSlice({
    name: NOTICIAS_NAME,
    initialState,
    reducers: {
        setNoticias: (state: Noticia[], action: PayloadAction<Noticia[]>) => {
            const newNoticias = [...action.payload];
            const oldState = [...state];
            if (oldState.length <= 0) return [...state, ...newNoticias];
            return [...state];
        },
        resetNoticias: () => {
            const newInitialState: Noticia[] = [];
            return [...newInitialState];
        },
    },
});

export const { setNoticias, resetNoticias } = noticiasSlice.actions;
export default noticiasSlice.reducer;
