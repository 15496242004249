import { useState, useCallback } from 'react';
import { UseDatePickerNewProps } from '../models/hooks/useDatePickerNew';

export default function useDatePickerNew({ date = new Date() }: UseDatePickerNewProps): [Date, (date: Date) => void] {
    const [selectedDate, setSelectedDate] = useState<Date>(date);

    const onDateChange = useCallback((date: Date) => {
        if (date) setSelectedDate(date);
    }, []);
    return [selectedDate, onDateChange];
}
