import { toast } from 'react-toastify';
import { signOut } from 'firebase/auth';
import { auth } from 'database';
import { db } from 'database';
import { doc, setDoc } from 'firebase/firestore';
import { useAppSelector } from 'redux/store';
import { userSelector } from 'redux/selectores';
import { useDispatch } from 'react-redux';
import { logOutUser } from 'redux/user';
import { resetEmployees } from 'redux/employes';
import { USERS_PATH } from 'constants/paths';
import { useCallback } from 'react';
import { estados } from 'constants/users';
import useRecordError from 'hooks/aws/useRecordError';
import { WEIK_CUSTOM_ERROR } from 'constants/aws';

const useLogOut = () => {
    const { user } = useAppSelector(userSelector);
    const dispatch = useDispatch();
    const { recordError } = useRecordError();

    const logOut = useCallback(async () => {
        if (auth.currentUser) {
            try {
                await setDoc(doc(db, USERS_PATH, user.id), { estado: estados.offline }, { merge: true });
                await signOut(auth);
                toast.success(`Has cerrado sesión correctamente`);
            } catch (error) {
                recordError(WEIK_CUSTOM_ERROR + '_logOut', error);
                toast.error('¡Ups parece que ha ocurrido un error, intenta de nuevo más tarde!');
            }
        }
        setTimeout(() => {
            dispatch(logOutUser());
        }, 2500);
        dispatch(resetEmployees());
    }, [dispatch, recordError, user.id]);

    return {
        logOut,
    };
};

export default useLogOut;
