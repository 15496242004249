import { Suspense, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { loading } from 'components/SuspenseLoading';
import { getBoolean } from 'utils/getBoolean';
import packageJson from '../../package.json';
import 'react-toastify/ReactToastify.min.css';

const DefaultLayout = () => {
    const isTestingEnv: boolean = useMemo(() => {
        return getBoolean(process.env.REACT_APP_ISTESTING ?? 'false');
    }, []);

    return (
        <>
            {isTestingEnv && (
                <div className="alert alert-info position-absolute" role="alert" style={{ zIndex: '9999' }}>
                    TEST ENV - {packageJson.version}
                </div>
            )}
            <Suspense fallback={loading()}>
                <Outlet />
                <ToastContainer
                    position="top-right"
                    autoClose={1500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Suspense>
        </>
    );
};
export default DefaultLayout;
