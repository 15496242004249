import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ClientItem } from '@models/clients';
import { getDecripted } from 'utils/getEncript';
import { CLIENTES_NAME, SESSION_STORAGE_CLIENTS } from 'constants/redux';

const clientsFromSessionStorageString = sessionStorage.getItem(SESSION_STORAGE_CLIENTS)
    ? getDecripted(
          sessionStorage.getItem(SESSION_STORAGE_CLIENTS) ?? '',
          process.env.REACT_APP_ENCRYPTED_PASSWORD ?? ''
      )
    : '';
const clientsFromSessionStorageObject: ClientItem[] = clientsFromSessionStorageString
    ? JSON.parse(clientsFromSessionStorageString)
    : [];

const initialState: ClientItem[] = [...clientsFromSessionStorageObject];

export const clientsSlice = createSlice({
    name: CLIENTES_NAME,
    initialState,
    reducers: {
        setClients: (state: any, action: PayloadAction<ClientItem[]>) => {
            const newClients = [...action.payload];
            const oldState = [...state];
            if (oldState.length <= 0) return [...state, ...newClients];
            return [...state];
        },
        updateClient: (
            state: any,
            action: PayloadAction<{ newClientItem: ClientItem; previousClientItemDomain: string }>
        ) => {
            const { newClientItem, previousClientItemDomain } = action.payload;
            const newClients = [...state];
            const newClientsToBeStored: ClientItem[] = newClients.map((client) => {
                if (client.domain === previousClientItemDomain) {
                    return { ...client, ...newClientItem };
                } else {
                    return client;
                }
            });

            return [...newClientsToBeStored];
        },
    },
});

export const { setClients, updateClient } = clientsSlice.actions;
export default clientsSlice.reducer;
