import { AwsRum, AwsRumConfig } from "aws-rum-web";
import packageJson from '../../package.json';

let awsRum: AwsRum = null;
try {
    const config: AwsRumConfig = {
        sessionSampleRate: 1,
        identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
        endpoint: process.env.REACT_APP_ENDPOINT,
        telemetries: [['errors', 'performance', {
            ignore: (entry: PerformanceEntry) => {
                return entry.entryType !== 'resource';
            }
        }]],
        allowCookies: true,
        enableXRay: false,
    };
    const APPLICATION_ID: string = process.env.REACT_APP_APPLICATION_ID;
    const APPLICATION_VERSION: string = packageJson.version;
    const APPLICATION_REGION: string = process.env.REACT_APP_APPLICATION_REGION;
    awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
} catch (error) {
    console.log("😰 ~ error:", error);
    // Ignore errors thrown during CloudWatch RUM web client initialization
}

export default awsRum;