import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { pptsObjectWrap, projectElementToArray, projectToBeUpdated } from '@models/projects';
import { PROJECTS_NAME } from 'constants/redux';

const initialState: pptsObjectWrap = {
    isLoading: true,
    projectPpts: [],
    projectPptsFiltered: [],
    projectMotions: [],
    projectMotionsFiltered: [],
};

export const projectsSlice = createSlice({
    name: PROJECTS_NAME,
    initialState,
    reducers: {
        setProjectPpts: (state: any, action: PayloadAction<projectElementToArray[]>) => {
            const newProjectPpts = [...action.payload];
            return { ...state, projectPpts: [...newProjectPpts], projectPptsFiltered: [...newProjectPpts] };
        },
        setProjectPptsFiltered: (state: any, action: PayloadAction<projectElementToArray[]>) => {
            const newProjectPptsFiltered = [...action.payload];
            return { ...state, projectPptsFiltered: [...newProjectPptsFiltered] };
        },
        setProjectmotions: (state: any, action: PayloadAction<projectElementToArray[]>) => {
            const newProjectMotions = [...action.payload];
            return { ...state, projectMotions: [...newProjectMotions], projectMotionsFiltered: [...newProjectMotions] };
        },
        setProjectMotionsFiltered: (state: any, action: PayloadAction<projectElementToArray[]>) => {
            const newProjectMotionsFiltered = [...action.payload];
            return { ...state, projectMotionsFiltered: [...newProjectMotionsFiltered] };
        },
        setUpdatePpts: (state: any, action: PayloadAction<projectToBeUpdated>) => {
            let pptObjToUpdate: any;
            let pptObjToUpdateFilter: any;
            const newStateProjectPptsArray = state.projectPpts.map((project: any) => {
                if (project.client === action.payload.cliente) {
                    const pptArray = project.projectArray || [];
                    const newObj: projectElementToArray = {
                        client: action.payload.cliente,
                        projectArray: [],
                    };
                    for (const ppt of pptArray) {
                        let flagSubRow = false;
                        if (ppt.subRows.length > 0) {
                            const index = ppt.subRows.findIndex((el: any) => el.id === action.payload.idToBeUpdated);
                            if (index >= 0) {
                                const newSubRows = [...ppt.subRows];
                                const keys: string = Object.keys(action.payload.propertyToBeUpdated)[0];
                                newSubRows[index] = {
                                    ...newSubRows[index],
                                    [keys]: action.payload.propertyToBeUpdated[keys],
                                };
                                pptObjToUpdate = {
                                    ...ppt,
                                    subRows: [...newSubRows],
                                };
                                flagSubRow = true;
                            }
                        }
                        if (ppt.id === action.payload.idToBeUpdated) {
                            const keys: string = Object.keys(action.payload.propertyToBeUpdated)[0];
                            pptObjToUpdate = {
                                ...ppt,
                                [keys]: action.payload.propertyToBeUpdated[keys],
                            };
                        } else if (!flagSubRow) pptObjToUpdate = { ...ppt };
                        newObj.projectArray?.push({ ...pptObjToUpdate });
                    }
                    return { ...newObj };
                }
                return { ...project };
            });
            const newStateProjectPptsArrayFilter = state.projectPptsFiltered.map((project: any) => {
                if (project.client === action.payload.cliente) {
                    const pptArray = project.projectArray || [];
                    const newObj: projectElementToArray = {
                        client: action.payload.cliente,
                        projectArray: [],
                    };
                    for (const ppt of pptArray) {
                        let flagSubRow = false;
                        if (ppt.subRows.length > 0) {
                            const index = ppt.subRows.findIndex((el: any) => el.id === action.payload.idToBeUpdated);
                            if (index >= 0) {
                                const newSubRows = [...ppt.subRows];
                                const keys: string = Object.keys(action.payload.propertyToBeUpdated)[0];
                                newSubRows[index] = {
                                    ...newSubRows[index],
                                    [keys]: action.payload.propertyToBeUpdated[keys],
                                };
                                pptObjToUpdateFilter = {
                                    ...ppt,
                                    subRows: [...newSubRows],
                                };
                                flagSubRow = true;
                            }
                        }
                        if (ppt.id === action.payload.idToBeUpdated) {
                            const keys: string = Object.keys(action.payload.propertyToBeUpdated)[0];
                            pptObjToUpdateFilter = {
                                ...ppt,
                                [keys]: action.payload.propertyToBeUpdated[keys],
                            };
                        } else if (!flagSubRow) pptObjToUpdateFilter = { ...ppt };
                        newObj.projectArray?.push({ ...pptObjToUpdateFilter });
                    }
                    return { ...newObj };
                }
                return { ...project };
            });

            return {
                ...state,
                projectPpts: [...newStateProjectPptsArray],
                projectPptsFiltered: [...newStateProjectPptsArrayFilter],
            };
        },
        setUpdateMotions: (state: any, action: PayloadAction<projectToBeUpdated>) => {
            let motionObjToUpdate: any;
            let motionObjToUpdateFilter: any;
            const newStateProjectMotionsArray = state.projectMotions.map((project: any) => {
                if (project.client === action.payload.cliente) {
                    const motionArray = project.projectArray || [];
                    const newObj: projectElementToArray = {
                        client: action.payload.cliente,
                        projectArray: [],
                    };
                    for (const motion of motionArray) {
                        let flagSubRow = false;
                        if (motion.subRows.length > 0) {
                            const index = motion.subRows.findIndex((el: any) => el.id === action.payload.idToBeUpdated);
                            if (index >= 0) {
                                const newSubRows = [...motion.subRows];
                                const keys: string = Object.keys(action.payload.propertyToBeUpdated)[0];
                                newSubRows[index] = {
                                    ...newSubRows[index],
                                    [keys]: action.payload.propertyToBeUpdated[keys],
                                };
                                motionObjToUpdate = {
                                    ...motion,
                                    subRows: [...newSubRows],
                                };
                                flagSubRow = true;
                            }
                        }
                        if (motion.id === action.payload.idToBeUpdated) {
                            const keys: string = Object.keys(action.payload.propertyToBeUpdated)[0];
                            motionObjToUpdate = {
                                ...motion,
                                [keys]: action.payload.propertyToBeUpdated[keys],
                            };
                        } else if (!flagSubRow) motionObjToUpdate = { ...motion };
                        newObj.projectArray?.push({ ...motionObjToUpdate });
                    }
                    return { ...newObj };
                }
                return { ...project };
            });
            const newStateProjectMotionsArrayFilter = state.projectMotionsFiltered.map((project: any) => {
                if (project.client === action.payload.cliente) {
                    const motionArray = project.projectArray || [];
                    const newObj: projectElementToArray = {
                        client: action.payload.cliente,
                        projectArray: [],
                    };
                    for (const motion of motionArray) {
                        let flagSubRow = false;
                        if (motion.subRows.length > 0) {
                            const index = motion.subRows.findIndex((el: any) => el.id === action.payload.idToBeUpdated);
                            if (index >= 0) {
                                const newSubRows = [...motion.subRows];
                                const keys: string = Object.keys(action.payload.propertyToBeUpdated)[0];
                                newSubRows[index] = {
                                    ...newSubRows[index],
                                    [keys]: action.payload.propertyToBeUpdated[keys],
                                };
                                motionObjToUpdateFilter = {
                                    ...motion,
                                    subRows: [...newSubRows],
                                };
                                flagSubRow = true;
                            }
                        }
                        if (motion.id === action.payload.idToBeUpdated) {
                            const keys: string = Object.keys(action.payload.propertyToBeUpdated)[0];
                            motionObjToUpdateFilter = {
                                ...motion,
                                [keys]: action.payload.propertyToBeUpdated[keys],
                            };
                        } else if (!flagSubRow) motionObjToUpdateFilter = { ...motion };
                        newObj.projectArray?.push({ ...motionObjToUpdateFilter });
                    }
                    return { ...newObj };
                }
                return { ...project };
            });
            return {
                ...state,
                projectMotions: [...newStateProjectMotionsArray],
                projectMotionsFiltered: [...newStateProjectMotionsArrayFilter],
            };
        },
        setLoadingPpts: (state: any, action: PayloadAction<boolean>) => {
            const loadingFlag: boolean = action.payload;
            return { ...state, isLoading: loadingFlag };
        },
    },
});

export const {
    setProjectPpts,
    setProjectmotions,
    setLoadingPpts,
    setUpdatePpts,
    setUpdateMotions,
    setProjectPptsFiltered,
    setProjectMotionsFiltered,
} = projectsSlice.actions;
export default projectsSlice.reducer;
