export const UTILIDADES_NAME = 'utilidades';
export const USER_NAME = 'user';
export const PROJECTS_NAME = 'projects';
export const SELECTED_ROWS_NAME = 'selectedRows';
export const RESIZE_LEFT_MENU_NAME = 'resizeLeftSideMenu';
export const MODALS_HORARIO_NAME = 'modalsHorarios';
export const NOTICIAS_NAME = 'noticias';
export const ACCIONES_NAME = 'acciones';
export const EMPLOYEES_NAME = 'employees';
export const CURRENT_CLIENT_NAME = 'currentClient';
export const CLIENTES_NAME = 'clients';

export const LOCAL_STORAGE_CLIENT = 'cli';
export const SESSION_STORAGE_CLIENTS = 'clis';
