import { LoadComponentProps } from '@models/routes/loadComponent';
import { loading } from 'components/SuspenseLoading';
import { DESIGNERS_ROUTE } from 'constants/routes';
import { roles } from 'constants/users';
import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { userSelector } from 'redux/selectores';
import { useAppSelector } from 'redux/store';

export const LoadComponent = ({ component: Component, roles: thisRoles }: LoadComponentProps) => {
    const { user, domain } = useAppSelector(userSelector);

    if (thisRoles && !thisRoles.includes(user.role ?? '')) {
        if (user.role === roles.diseñador) return <Navigate to={`/${domain}/${DESIGNERS_ROUTE}`} replace />;
        else return <Navigate to={`/${domain}`} replace />;
    }

    return (
        <Suspense fallback={loading()}>
            <Component />
        </Suspense>
    );
};
