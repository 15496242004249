const DEFAULT_PASSWORD = '000000';

/**
 * client | admin | diseñador | externo
 */
enum roles {
    client = 'client',
    admin = 'admin',
    diseñador = 'diseñador',
    externo = 'externo',
}
/**
 * [admin]
 */
const adminUsers: string[] = [roles.admin];
/**
 * [admin, diseñador, externo]
 */
const weikUsers: string[] = [roles.admin, roles.diseñador, roles.externo];
/**
 * [client]
 */
const clientUsers: string[] = [roles.client];
/**
 * online | offline | inactivo
 */
enum estados {
    online = 'online',
    offline = 'offline',
    inactivo = 'inactivo',
}

export { adminUsers, weikUsers, clientUsers, roles, estados, DEFAULT_PASSWORD };
