import { forwardRef, useMemo } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es'; // the locale you want
import { DatepickerInputProps } from '../models/components/datepicker';
import { HyperDatepickerRangeProps } from '../models/components/datepickerRange';

registerLocale('es', es);

/* Datepicker with Input */
const DatepickerInput = forwardRef<HTMLInputElement, DatepickerInputProps>((props, ref) => {
    return (
        <input
            disabled={props.disabled}
            type="text"
            className={`form-control date cursor-text`}
            onClick={props.onClick}
            value={props.value}
            onChange={() => {}}
            ref={ref}
            name={props.name}
        />
    );
});

/* Datepicker with Addon Input */
const DatepickerInputWithAddon = forwardRef<HTMLInputElement, DatepickerInputProps>((props, ref) => (
    <div className="input-group" ref={ref}>
        <div className="input-group-append">
            <span className="input-group-text bg-primary border-primary text-white">
                <i className="mdi mdi-calendar-range font-13"></i>
            </span>
        </div>
        <input
            type="text"
            className="form-control form-control-light"
            onClick={props.onClick}
            value={props.value}
            name={props.name}
            readOnly
        />
    </div>
));

const HyperDatepickerRange = (props: HyperDatepickerRangeProps) => {
    const input: JSX.Element = useMemo(() => {
        if ((props.hideAddon || false) === true)
            return <DatepickerInput name={props.name} inputClass={props.inputClass!} />;
        else return <DatepickerInputWithAddon name={props.name} inputClass={props.inputClass!} />;
    }, [props.hideAddon, props.inputClass, props.name]);

    return (
        <DatePicker
            disabled={props.disabled ?? false}
            customInput={input}
            className={`form-control ${props.inputClass}`}
            onChange={(date) => {
                props.onChange(date!);
            }}
            dateFormat={props.dateFormat ?? 'dd/MM/yyyy'}
            name={props.name}
            selectsRange={props.isRange}
            startDate={props.startDate}
            endDate={props.endDate}
            monthsShown={2}
            inline={props.inline}
            excludeDates={props.disabledDates ? props.disabledDates : []}
            highlightDates={
                props.highlightDates
                    ? [
                          {
                              'react-datepicker__day--highlighted-custom-3': [...props.highlightDates],
                          },
                      ]
                    : []
            }
            calendarClassName="calendar-with-divider"
            locale={es}
        />
    );
};

export default HyperDatepickerRange;
