import { createSelector } from '@reduxjs/toolkit';
import { weikUsers } from 'constants/users';
import { RootState } from 'redux/store';
import { projectType } from '@models/projects';

const selectUser = (state: RootState) => state.user;
const selectUserDate = (state: RootState) => state.user.user;
const selectUserDomain = (state: RootState) => state.user.domain;
const selectClients = (state: RootState) => state.clients;
const selectProjects = (state: RootState) => state.projects;
const selectSelectedRows = (state: RootState) => state.selectedRows;
const selectCurrenClient = (state: RootState) => state.currentClient;
const selectEmployes = (state: RootState) => state.employees;
const selectUtilidades = (state: RootState) => state.utilidades;
const selectModales = (state: RootState) => state.modalsHorarios;
const selectAcciones = (state: RootState) => state.acciones;
const selectNoticias = (state: RootState) => state.noticias;
const selectLeftSideMenuResize = (state: RootState) => state.resizeLeftSideMenu;

export const utilidadesSelector = createSelector([selectUtilidades], (utilidades) => {
    return utilidades;
});

export const employesSelector = createSelector([selectEmployes], (employes) => {
    return employes;
});

export const userSelector = createSelector([selectUser], (user) => {
    return user;
});

export const userSelectorData = createSelector([selectUserDate], (user) => {
    return user;
});

export const userDomainSelector = createSelector([selectUserDomain], (domain) => {
    return domain;
});

export const clientsSelector = createSelector([selectClients], (clients) => {
    return clients;
});

export const clientsOptionsSelector = createSelector([selectClients], (clients) => {
    return clients.map((client) => {
        return {
            value: client.domain,
            label: client.nombre,
        };
    });
});

export const clientsMenuSelector = createSelector([selectClients], (clients) => {
    return clients.map((client) => {
        return {
            key: `p_key_${client.domain}`,
            label: client.nombre,
            isTitle: false,
            icon: 'mdi mdi-account-supervisor',
            role: weikUsers,
            children: [
                {
                    key: 'ppts',
                    label: 'PPTS',
                    url: `${client.domain}/ppts`,
                    parentKey: `p_key_${client.domain}`,
                },
                {
                    key: 'motions',
                    label: 'MOTIONS',
                    url: `${client.domain}/motions`,
                    parentKey: `p_key_${client.domain}`,
                },
            ],
        };
    });
});

export const projectsSelector = createSelector([selectProjects], (projects) => {
    return projects;
});

export const projectsPptsSelector = createSelector([selectProjects], (projects) => {
    return projects.projectPpts.reduce((acc, project) => {
        const { projectArray } = project;
        const newAcc = [...acc, ...projectArray];
        return newAcc;
    }, [] as projectType[]);
});

export const projectsMotionsSelector = createSelector([selectProjects], (projects) => {
    return projects.projectMotions.reduce((acc, project) => {
        const { projectArray } = project;
        const newAcc = [...acc, ...projectArray];
        return newAcc;
    }, [] as projectType[]);
});

export const projectsUserSelector = createSelector([selectUser, selectProjects], (user, projects) => {
    return {
        user,
        projects,
    };
});

export const projectsUserSelectedRowsSelector = createSelector(
    [selectUser, selectProjects, selectSelectedRows],
    (user, projects, selectedRows) => {
        return {
            user: user.user,
            projects,
            selectedRows,
        };
    }
);

export const forClientsPageSelector = createSelector(
    [selectUser, selectClients, selectCurrenClient],
    (user, clients, currentClientInfo) => {
        return {
            user: user.user,
            domain: user.domain,
            clientsMenu: clients,
            currentClient: currentClientInfo,
        };
    }
);

export const getCurrentClientInfo = createSelector([selectCurrenClient], (currentClientInfo) => currentClientInfo);

export const getModalsInfo = createSelector([selectModales], (modales) => modales);

export const getAcciones = createSelector([selectAcciones], (acciones) => acciones);

export const getNoticias = createSelector([selectNoticias], (noticias) => noticias);

export const getLeftSideMenuResize = createSelector(
    [selectLeftSideMenuResize],
    (resizeLeftSideMenu) => resizeLeftSideMenu
);
