import { db } from 'database';
import { Unsubscribe, collection, onSnapshot } from 'firebase/firestore';
import { EmployeeData } from '@models/user';
import { useDispatch } from 'react-redux';
import { resetEmployees, setEmployes } from 'redux/employes';
import { USERS_PATH } from 'constants/paths';
import { useCallback, useState } from 'react';

export default function useListenerEmployees() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const getUpdatesOnEmployees = useCallback((): Unsubscribe => {
        setLoading(true);
        const unsubscribe = onSnapshot(collection(db, USERS_PATH), (doc) => {
            const childrenObjEmployees: EmployeeData[] = [];
            doc.docs.forEach((doc) => {
                childrenObjEmployees.push({
                    ...(doc.data() as EmployeeData),
                    id: doc.id,
                });
            });
            dispatch(resetEmployees());
            dispatch(setEmployes(childrenObjEmployees));
            setLoading(false);
        }, () => {
            setLoading(false);
        });
        return unsubscribe;
    }, [dispatch]);

    return {
        getUpdatesOnEmployees,
        loading
    };
}
