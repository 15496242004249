import ConfirmExtraHoursDialog from 'components/ConfirmExtraHoursDialog';
import { useToggleV2 } from 'hooks';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Row, Col, Button, OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setWorkingTime, setCheckOutWorkingTime } from 'redux/modalsHorarios';
import { userSelector, getModalsInfo } from 'redux/selectores';
import { useAppSelector } from 'redux/store';
import { useTime } from 'react-timer-hook';
import { HorarioType, HorasTrabajoType } from '@models/user';
import { giveMeCorrectTimeDisplayedForDate } from 'utils/getCropedText';
import useOfflineUser from 'endpoints/useOfflineUser';
import useCheckInLaboral from 'endpoints/useCheckInLaboral';
import useCheckOutLaboral from 'endpoints/useCheckOutLaboral';
import useCheckInExtras from 'endpoints/useCheckInExtras';

const HorarioStatus = () => {
    const { user } = useAppSelector(userSelector);
    const { seconds, minutes, hours, ampm } = useTime({ format: '12-hour' });
    const { isInExtraWorkingTime, isInWorkingTime, isModalOpen } = useAppSelector(getModalsInfo);
    const [showExtraHoursDialogs, showExtraHoursDialog, hideExtraHoursDialog] = useToggleV2();
    const [buttonCheckbox, setButtonCheckbox] = useState<boolean>(false);
    const { offlineMember } = useOfflineUser();
    const dispatch = useDispatch();
    const { checkInLaboral } = useCheckInLaboral();
    const { checkOutLaboral } = useCheckOutLaboral();
    const { checkInExtra } = useCheckInExtras();

    useEffect(() => {
        if (isInWorkingTime && isInExtraWorkingTime && isModalOpen) {
            showExtraHoursDialog();
        }
    }, [isInExtraWorkingTime, isInWorkingTime, isModalOpen, showExtraHoursDialog]);

    const isDisabledCachedCheckInLaboral: boolean = useMemo(() => {
        if (buttonCheckbox) return false;
        if (!isInWorkingTime && !isInExtraWorkingTime && !isModalOpen) return false;
        else return true;
    }, [buttonCheckbox, isInExtraWorkingTime, isInWorkingTime, isModalOpen]);

    const isDisabledCachedCheckOutLaboral: boolean = useMemo(() => {
        if (buttonCheckbox) return false;
        if (isInWorkingTime && !isInExtraWorkingTime && !isModalOpen) return false;
        else return true;
    }, [buttonCheckbox, isInExtraWorkingTime, isInWorkingTime, isModalOpen]);

    const checkInLaboralOnClick = useCallback(async () => {
        await checkInLaboral();
        dispatch(setWorkingTime());
    }, [checkInLaboral, dispatch]);

    const checkOutLaboralOnClick = useCallback(async () => {
        await checkOutLaboral();
        await offlineMember(user.id);
        dispatch(setCheckOutWorkingTime());
    }, [checkOutLaboral, dispatch, offlineMember, user.id]);

    const enabledCheckButton = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setButtonCheckbox(event.target.checked);
    }, []);

    const currentCheckIn: string = useMemo(() => {
        if (user.horasTrabajo.length > 0) {
            const todayDate = new Date().toLocaleDateString();
            const horasTrabajo: HorasTrabajoType | null = user.horasTrabajo.reduce((acc, horaTrabajo) => {
                const { dia } = horaTrabajo;
                if (dia === todayDate) return horaTrabajo;
                return acc;
            }, null);

            if (!horasTrabajo) {
                return 'No ha hecho ckeck-In';
            } else {
                return giveMeCorrectTimeDisplayedForDate(
                    new Date(
                        `${horasTrabajo.dia.split('/').reverse().join('/')}, ${horasTrabajo.checkIn}`
                    ).toLocaleDateString('es-ES', {
                        weekday: 'short',
                        month: 'long',
                        day: 'numeric',
                        hour12: true,
                        hour: 'numeric',
                        minute: 'numeric',
                    })
                );
            }
        } else return 'Aún no hay';
    }, [user.horasTrabajo]);

    const currentCheckOut: string = useMemo(() => {
        if (user.horasTrabajo.length > 0) {
            const todayDate = new Date().toLocaleDateString();
            const horasTrabajo: HorasTrabajoType | null = user.horasTrabajo.reduce((acc, horaTrabajo) => {
                const { dia } = horaTrabajo;
                if (dia === todayDate) return horaTrabajo;
                return acc;
            }, null);

            if (!horasTrabajo) {
                return 'No ha hecho ckeck-In';
            } else {
                if (horasTrabajo.checkOut === '23:59:00') {
                    return 'No ha hecho check-out';
                } else {
                    return giveMeCorrectTimeDisplayedForDate(
                        new Date(
                            `${horasTrabajo.dia.split('/').reverse().join('/')}, ${horasTrabajo.checkOut}`
                        ).toLocaleDateString('es-ES', {
                            weekday: 'short',
                            month: 'long',
                            day: 'numeric',
                            hour12: true,
                            hour: 'numeric',
                            minute: 'numeric',
                        })
                    );
                }
            }
        } else return 'Aún no hay';
    }, [user.horasTrabajo]);

    const riseShakingCheckIn: boolean = useMemo(() => {
        return !isDisabledCachedCheckInLaboral;
    }, [isDisabledCachedCheckInLaboral]);

    const riseShakingCheckOut: boolean = useMemo(() => {
        if (user.horario) {
            const today: Date = new Date();
            const horario: HorarioType[] = (user.horario as HorarioType[]).filter(
                (thisHorario) =>
                    new Date(thisHorario.rangoFechas[0]) < today && today < new Date(thisHorario.rangoFechas[1])
            );
            if (!!horario.length && !isDisabledCachedCheckOutLaboral) {
                const nowHour: number = new Date().getHours();
                const suposedCheckOutTime: number = +horario[0].horaInicio.split(':')[0] + +horario[0].horasDeTrabajo;
                return nowHour >= suposedCheckOutTime;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }, [isDisabledCachedCheckOutLaboral, user.horario]);

    return (
        <>
            {
                <ConfirmExtraHoursDialog
                    show={showExtraHoursDialogs}
                    onHide={hideExtraHoursDialog}
                    checkInExtra={checkInExtra}
                />
            }

            <Card className="shadow-none widget-inline h-100 mb-0" style={{ minHeight: '114px' }}>
                <Card.Body className="p-0">
                    <Row className="g-0" style={{ minHeight: '114px' }}>
                        <Col sm={6} lg={6}>
                            <Card className="shadow-none m-0 h-100">
                                <Card.Body className="p-2">
                                    <div className="w-100 h-100 p-0 m-0 d-flex flex-column justify-content-between align-items-center">
                                        {/* Botones laborales */}
                                        <OverlayTrigger
                                            placement="auto"
                                            overlay={
                                                <Tooltip>
                                                    Marcar CHECK-IN de la jornada laboral a las:{' '}
                                                    {
                                                        <>
                                                            <span>{hours === 0 ? '12' : hours}</span>:
                                                            <span>{minutes}</span>:<span>{seconds}</span>
                                                            <span> {ampm.toUpperCase()}</span>
                                                        </>
                                                    }{' '}
                                                    el día de hoy.
                                                </Tooltip>
                                            }>
                                            <Button
                                                disabled={isDisabledCachedCheckInLaboral}
                                                className={`w-100 ${riseShakingCheckIn && 'rise-shake-soft'}`}
                                                variant="success"
                                                onClick={checkInLaboralOnClick}>
                                                <i className="mdi mdi-arrow-right-bold me-1"></i>
                                                Empezar jornada laboral
                                            </Button>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement="auto"
                                            overlay={
                                                <Tooltip>
                                                    Marcar CHECK-OUT de la jornada laboral a las:{' '}
                                                    {
                                                        <>
                                                            <span>{hours === 0 ? '12' : hours}</span>:
                                                            <span>{minutes}</span>:<span>{seconds}</span>
                                                            <span> {ampm.toUpperCase()}</span>
                                                        </>
                                                    }{' '}
                                                    el día de hoy.
                                                </Tooltip>
                                            }>
                                            <Button
                                                disabled={isDisabledCachedCheckOutLaboral}
                                                className={`w-100 ${riseShakingCheckOut && 'rise-shake-soft'}`}
                                                variant="danger"
                                                onClick={checkOutLaboralOnClick}>
                                                <i className="mdi mdi-arrow-left-bold me-1"></i>
                                                Finalizar jornada laboral
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col sm={6} lg={6}>
                            <Card className="shadow-none m-0 border-start h-100">
                                <Card.Body className="d-flex flex-column  text-center p-2 justify-content-center">
                                    <Form.Check
                                        className="mb-0 px-3"
                                        type="checkbox"
                                        id="show-check-buttons"
                                        label="Habilitar botones jornada laboral"
                                        onChange={enabledCheckButton}
                                    />
                                    <h2 className="mb-0 p-0 d-flex align-item-center justify-content-center">
                                        <i className="mdi mdi-arrow-right-bold font-18 text-success"></i>
                                        <span className="text-success font-16 text-capitalize">{currentCheckIn}</span>
                                    </h2>
                                    <h2 className="mb-0 p-0 d-flex align-item-center justify-content-center">
                                        <i className="mdi mdi-arrow-left-bold font-18 text-danger"></i>
                                        <span className="text-success font-16 text-capitalize">{currentCheckOut}</span>
                                    </h2>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );
};

export default HorarioStatus;
