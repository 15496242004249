import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { EmployeeData, HorarioType } from '@models/user';
import { estados } from 'constants/users';
import { EMPLOYEES_NAME } from 'constants/redux';

const initialState: EmployeeData[] = [];

export const employesSlice = createSlice({
    name: EMPLOYEES_NAME,
    initialState,
    reducers: {
        setEmployes: (state: EmployeeData[], action: PayloadAction<EmployeeData[]>) => {
            const newEmployes = [...action.payload];
            const oldState = [...state];
            if (oldState.length <= 0) return [...state, ...newEmployes];
            return [...state];
        },
        removeEmploye: (state: EmployeeData[], action: PayloadAction<string>) => {
            const oldState = [...state];
            const newState = oldState.map((employe) => {
                if (employe.id === action.payload) return { ...employe, estado: estados.inactivo };
                else return employe;
            });
            return [...newState];
        },
        updateEmployeHorario: (
            state: EmployeeData[],
            action: PayloadAction<{ horario: HorarioType; employeId: string }>
        ) => {
            const oldState = [...state];
            const newState = oldState.map((employe) => {
                if (employe.id === action.payload.employeId) {
                    if (employe.horario)
                        return { ...employe, horario: [...employe.horario, { ...action.payload.horario }] };
                    else return { ...employe, horario: [{ ...action.payload.horario }] };
                } else return employe;
            });
            return [...newState];
        },
        resetEmployees: () => {
            const newInitialState: EmployeeData[] = [];
            return [...newInitialState];
        },
    },
});

export const { setEmployes, removeEmploye, resetEmployees, updateEmployeHorario } = employesSlice.actions;
export default employesSlice.reducer;
