import { FC, useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import useLogOut from 'endpoints/useLogOut';
import { useDispatch } from 'react-redux';
import { setResetTimeFlags, setExtraWorkingTime } from 'redux/modalsHorarios';
import { ConfirmExtraHoursDialogProps } from '@models/components/confirmExtraHoursDialog';

const ConfirmExtraHoursDialog: FC<ConfirmExtraHoursDialogProps> = ({
    show,
    onHide,
    checkInExtra,
}: ConfirmExtraHoursDialogProps) => {
    const [isLoadingLogOut, setIsLoadingLogOut] = useState<boolean>(false);
    const { logOut } = useLogOut();
    const dispatch = useDispatch();

    const handleLogout = useCallback(() => {
        setIsLoadingLogOut(true);
        dispatch(setResetTimeFlags());
        logOut();
    }, [dispatch, logOut]);

    const continuarOnClick = useCallback(() => {
        onHide();
        checkInExtra();
        dispatch(setExtraWorkingTime());
    }, [checkInExtra, dispatch, onHide]);

    return (
        <Modal backdrop="static" centered show={show} size="xl">
            <Modal.Header className="modal-colored-header bg-danger">
                <h2 className="modal-title text-uppercase">
                    Terminaste tus horas de trabajo asignadas para el día de hoy
                </h2>
            </Modal.Header>
            <Modal.Body>
                <h3>
                    <p className="weik-text-grey-500">¿ESTÁS DE ACUERDO CON TRABAJAR HORAS EXTRA?</p>
                    <p className="h4 lh-base">
                        <i className="mdi mdi-alert-decagram me-1 text-danger h3"></i>
                        <br></br>
                        1. Si sales de la plataforma desde el botón <span className="text-danger">NO, SALIR</span> de
                        este modal, no podrás hacer horas extras el día de hoy, ya que la joranda termina
                        automáticamente. <br></br>
                        2. Debes hacer 'check-in horas extras' para que estas cuenten
                    </p>
                </h3>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isLoadingLogOut} onClick={continuarOnClick} variant="success">
                    <h3>SÍ, CONTINUAR</h3>
                </Button>
                <Button disabled={isLoadingLogOut} onClick={handleLogout} variant="danger">
                    <h3>NO, SALIR</h3>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmExtraHoursDialog;
