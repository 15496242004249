import CryptoJS from 'crypto-js';

/**
 * Devuelve una cadena de texto encriptada
 */
export const setEncript = (textToBeEncripted: string, encriptedPassword: string): string => CryptoJS.AES.encrypt(textToBeEncripted, encriptedPassword).toString();

/**
 * Devuelve una cadena de texto desencriptada
 */
export const getDecripted = (encriptedtEXT: string, decriptedPassword: string): string => CryptoJS.AES.decrypt(encriptedtEXT, decriptedPassword).toString(CryptoJS.enc.Utf8);

