import { db } from 'database';
import { doc, setDoc } from 'firebase/firestore';
import { useCallback } from 'react';
import { USERS_PATH } from 'constants/paths';
import { estados } from 'constants/users';
import useRecordError from 'hooks/aws/useRecordError';
import { WEIK_CUSTOM_ERROR } from 'constants/aws';

export default function useOfflineUser() {
    const { recordError } = useRecordError();

    const offlineMember = useCallback(
        async (id: string): Promise<void> => {
            try {
                await setDoc(doc(db, USERS_PATH, id), { estado: estados.offline }, { merge: true });
            } catch (error) {
                recordError(WEIK_CUSTOM_ERROR + "_offlineMember", error);
            }
        },
        [recordError]
    );

    return {
        offlineMember,
    };
}
