import { WE_LOCAL_STORAGE_KEY, WT_LOCAL_STORAGE_KEY, MODAL_OPEN_LOCAL_STORAGE_KEY } from 'constants/horario';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setResetTimeFlags } from 'redux/modalsHorarios';
import { EmployeeData, HorasTrabajoType } from '@models/user';
import { clearLocalStorageItems } from 'utils/utilarios';
import useRecordEvent from './aws/useRecordEvent';
import { WEIK_CUSTOM_EVENT } from 'constants/aws';

export default function useHorario() {
    const { recordCustomEvent } = useRecordEvent();
    const dispatch = useDispatch();
 
    const resetHorario = useCallback(
        (employees: EmployeeData[], userId: string) => {
            const employee: EmployeeData = employees.filter((employee) => employee.id === userId)[0];
            const isInWorkingTime = localStorage.getItem(WT_LOCAL_STORAGE_KEY);
            const isInExtraWorkingTime = localStorage.getItem(WE_LOCAL_STORAGE_KEY);
            const isModalOpen = localStorage.getItem(MODAL_OPEN_LOCAL_STORAGE_KEY);
            const { horasTrabajo } = employee;
            const today = new Date().toLocaleDateString();
            let horaTrabajoDate: HorasTrabajoType[];
            if (horasTrabajo) horaTrabajoDate = horasTrabajo.filter((hora) => hora.dia === today);
            else horaTrabajoDate = [];
            if (isInWorkingTime || isInExtraWorkingTime || isModalOpen) {
                if (horaTrabajoDate.length <= 0) {
                    clearLocalStorageItems();
                    dispatch(setResetTimeFlags());
                    recordCustomEvent(WEIK_CUSTOM_EVENT, { from: 'useHorario', callback: 'resetHorario', accion: 'ha limpiado el horario para una nueva jornada' });
                    window.location.reload();
                }
            }
        },
        [dispatch, recordCustomEvent]
    );

    return {
        resetHorario,
    };
}
