import { Navigate } from 'react-router-dom';
import { userSelector } from 'redux/selectores';
import { useAppSelector } from 'redux/store';
import { DEFAULT_ROUTER_PATH } from 'constants/paths';

const Root = () => {
    const { isUserLoggedIn, domain } = useAppSelector(userSelector);
    if (isUserLoggedIn) return <Navigate to={domain} replace />;
    return <Navigate to={DEFAULT_ROUTER_PATH} replace />;
};
export default Root;
