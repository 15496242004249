import {
    USER_NAME,
    ACCIONES_NAME,
    NOTICIAS_NAME,
    RESIZE_LEFT_MENU_NAME,
    MODALS_HORARIO_NAME,
    UTILIDADES_NAME,
    PROJECTS_NAME,
    SELECTED_ROWS_NAME,
    EMPLOYEES_NAME,
    CLIENTES_NAME,
    CURRENT_CLIENT_NAME,
} from 'constants/redux';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import userReducer from './user';
import clientsReducer from './clients';
import selectedRowsReducer from './selectedRows';
import projectsReducer from './projects';
import currentClientReducer from './currentClient';
import employesReducer from './employes';
import utilidadesReducer from './utilidades';
import modalsReducer from './modalsHorarios';
import accionesReducer from './acciones';
import noticiasReducer from './noticias';
import resizeReducer from './resizeLeftSideMenu';

const rootReducer = combineReducers({
    [USER_NAME]: userReducer,
    [CURRENT_CLIENT_NAME]: currentClientReducer,
    [CLIENTES_NAME]: clientsReducer,
    [EMPLOYEES_NAME]: employesReducer,
    [SELECTED_ROWS_NAME]: selectedRowsReducer,
    [PROJECTS_NAME]: projectsReducer,
    [UTILIDADES_NAME]: utilidadesReducer,
    [MODALS_HORARIO_NAME]: modalsReducer,
    [ACCIONES_NAME]: accionesReducer,
    [NOTICIAS_NAME]: noticiasReducer,
    [RESIZE_LEFT_MENU_NAME]: resizeReducer,
});

export const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
