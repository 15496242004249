import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AccionToDb } from '@models/acciones';
import { ACCIONES_NAME } from 'constants/redux';

const initialState: AccionToDb[] = [];

export const accionesSlice = createSlice({
    name: ACCIONES_NAME,
    initialState,
    reducers: {
        setAcciones: (state: AccionToDb[], action: PayloadAction<AccionToDb[]>) => {
            const newAcciones = [...action.payload];
            const oldState = [...state];
            if (oldState.length <= 0) return [...state, ...newAcciones];
            return [...state];
        },
        resetAcciones: () => {
            const newInitialState: AccionToDb[] = [];
            return [...newInitialState];
        },
    },
});

export const { setAcciones, resetAcciones } = accionesSlice.actions;
export default accionesSlice.reducer;
