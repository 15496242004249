import { db } from 'database';
import { doc, setDoc } from 'firebase/firestore';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { userSelector } from 'redux/selectores';
import { toast } from 'react-toastify';
import { HorasTrabajoType } from '@models/user';
import { USERS_PATH } from 'constants/paths';
import { useDispatch } from 'react-redux';
import { updateUserWorkingHours } from 'redux/user';
import { giveMeCorrectTime } from 'utils/getCropedText';
import useRecordError from 'hooks/aws/useRecordError';
import { WEIK_CUSTOM_ERROR } from 'constants/aws';

export default function useCheckInLaboral() {
    const [isSavingCheckInLaboral, setIsSavingCheckInLaboral] = useState<boolean>(false);
    const { user } = useAppSelector(userSelector);
    const { recordError } = useRecordError();
    const dispatch = useDispatch();

    const checkInLaboral = useCallback(
        async (): Promise<void> => {
            setIsSavingCheckInLaboral(true);
            try {
                let horasTrabajo: HorasTrabajoType[] = [];
                const checkoutTime: Date = new Date();
                checkoutTime.setHours(23);
                checkoutTime.setMinutes(59);
                checkoutTime.setSeconds(0);
                if (user.horasTrabajo?.length > 0) {
                    let theDayExist: boolean = false;
                    const todayDate = new Date().toLocaleDateString();
                    horasTrabajo = user.horasTrabajo.map((horaTrabajo: HorasTrabajoType) => {
                        const { dia } = horaTrabajo;
                        if (dia === todayDate) {
                            theDayExist = true;
                            return {
                                dia,
                                checkIn: giveMeCorrectTime(new Date().toLocaleTimeString('es')),
                                checkOut: giveMeCorrectTime(checkoutTime.toLocaleTimeString('es')),
                                horasDeTrabajo: 0,
                                horasDeTrabajoEnSegundos: 0,
                            };
                        } else {
                            return horaTrabajo;
                        }
                    });
                    if (!theDayExist) {
                        horasTrabajo = [
                            ...user.horasTrabajo,
                            {
                                dia: new Date().toLocaleDateString(),
                                checkIn: giveMeCorrectTime(new Date().toLocaleTimeString('es')),
                                checkOut: giveMeCorrectTime(checkoutTime.toLocaleTimeString('es')),
                                horasDeTrabajo: 0,
                                horasDeTrabajoEnSegundos: 0,
                            },
                        ];
                    }
                } else {
                    horasTrabajo = [
                        {
                            dia: new Date().toLocaleDateString(),
                            checkIn: giveMeCorrectTime(new Date().toLocaleTimeString('es')),
                            checkOut: giveMeCorrectTime(checkoutTime.toLocaleTimeString('es')),
                            horasDeTrabajo: 0,
                            horasDeTrabajoEnSegundos: 0,
                        },
                    ];
                }
                const userRef = doc(db, USERS_PATH, user.id);
                await setDoc(userRef, { horasTrabajo }, { merge: true });
                dispatch(updateUserWorkingHours({ horasTrabajo }));
                toast.success('Check-in Hecho');
            } catch (error) {
                recordError(WEIK_CUSTOM_ERROR + "_checkInLaboral", error);
                toast.error('Hubo un error intentando hacer Check-in');
            } finally {
                setIsSavingCheckInLaboral(false);
            }
        },
        [dispatch, recordError, user.horasTrabajo, user.id]
    );

    return {
        isSavingCheckInLaboral,
        checkInLaboral,
    };
}
