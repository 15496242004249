/* BD PATHS */
export const ACCIONES_PATH: string = 'acciones';
export const NOTICIAS_PATH: string = 'noticias';
export const UTILIDADES_PATH: string = 'utilidades';
export const CLIENTS_PATH: string = 'clients';
export const STORAGE_CLIENTES_PATH: string = 'clientes';
export const USERS_PATH: string = 'clients/weik/users';

/* ROUTER PATHS */
export const DEFAULT_ROUTER_PATH: string = '/account/login';
