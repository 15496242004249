import { createSlice } from '@reduxjs/toolkit';
import { ModalsData } from '@models/modals';
import { WT_LOCAL_STORAGE_KEY, WE_LOCAL_STORAGE_KEY, MODAL_OPEN_LOCAL_STORAGE_KEY } from 'constants/horario';
import { MODALS_HORARIO_NAME } from 'constants/redux';

const isInWorkingTime: boolean = localStorage.getItem(WT_LOCAL_STORAGE_KEY)
    ? localStorage.getItem(WT_LOCAL_STORAGE_KEY) === 'true'
    : false;
const isInExtraWorkingTime: boolean = localStorage.getItem(WE_LOCAL_STORAGE_KEY)
    ? localStorage.getItem(WE_LOCAL_STORAGE_KEY) === 'true'
    : false;
const isModalOpen: boolean = localStorage.getItem(MODAL_OPEN_LOCAL_STORAGE_KEY)
    ? localStorage.getItem(MODAL_OPEN_LOCAL_STORAGE_KEY) === 'true'
    : false;

const initialState: ModalsData = { isInWorkingTime, isInExtraWorkingTime, isModalOpen };

export const modalsSlice = createSlice({
    name: MODALS_HORARIO_NAME,
    initialState,
    reducers: {
        setWorkingTime: (state: ModalsData) => {
            // Hace Check-in empieza el contador de horas de trabajo
            localStorage.setItem(WT_LOCAL_STORAGE_KEY, 'true');
            localStorage.setItem(WE_LOCAL_STORAGE_KEY, 'false');
            localStorage.setItem(MODAL_OPEN_LOCAL_STORAGE_KEY, 'false');
            return { ...state, isInWorkingTime: true, isInExtraWorkingTime: false, isModalOpen: false };
        },
        setCheckOutWorkingTime: (state: ModalsData) => {
            // hico checkout de working time
            localStorage.setItem(WT_LOCAL_STORAGE_KEY, 'true');
            localStorage.setItem(WE_LOCAL_STORAGE_KEY, 'true');
            localStorage.setItem(MODAL_OPEN_LOCAL_STORAGE_KEY, 'false');
            return { ...state, isInWorkingTime: true, isInExtraWorkingTime: true, isModalOpen: false };
        },
        setModalShouldBeOpen: (state: ModalsData) => {
            // Estado donde el modal esta abierto, debio hacer check-out
            localStorage.setItem(WT_LOCAL_STORAGE_KEY, 'true');
            localStorage.setItem(WE_LOCAL_STORAGE_KEY, 'true');
            localStorage.setItem(MODAL_OPEN_LOCAL_STORAGE_KEY, 'true');
            return { ...state, isInWorkingTime: true, isInExtraWorkingTime: true, isModalOpen: true };
        },
        setExtraWorkingTime: (state: ModalsData) => {
            // Ya hizo check-out y acepto el modal de horas extras
            localStorage.setItem(WT_LOCAL_STORAGE_KEY, 'false');
            localStorage.setItem(WE_LOCAL_STORAGE_KEY, 'true');
            localStorage.setItem(MODAL_OPEN_LOCAL_STORAGE_KEY, 'false');
            return { ...state, isInWorkingTime: false, isInExtraWorkingTime: true, isModalOpen: false };
        },
        setResetTimeFlags: (state: ModalsData) => {
            // No ha hecho check-in y los contadores estan pausados
            localStorage.removeItem(WT_LOCAL_STORAGE_KEY);
            localStorage.removeItem(WE_LOCAL_STORAGE_KEY);
            localStorage.removeItem(MODAL_OPEN_LOCAL_STORAGE_KEY);
            return { ...state, isInWorkingTime: false, isInExtraWorkingTime: false, isModalOpen: false };
        },
    },
});

export const { setWorkingTime, setCheckOutWorkingTime, setModalShouldBeOpen, setExtraWorkingTime, setResetTimeFlags } =
    modalsSlice.actions;
export default modalsSlice.reducer;
