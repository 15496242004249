import { Card, Col, Row } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import classNames from 'classnames';
import { FormInput } from 'components';
import { EmployeeData, Estados } from '@models/user';
import { employesSelector, getCurrentClientInfo, userSelectorData } from 'redux/selectores';
import { useAppSelector } from 'redux/store';
import { useCallback, useEffect, useState } from 'react';
import useSetEmployes from 'sockets/useListenerEmployes';
import { estados } from 'constants/users';
import { giveMeTheUserHeader } from 'utils/getCropedText';
import SkeletonLoader from 'components/SkeletonLoader/SkeletonLoader';

const TeamMembers = () => {
    const [employee, setEmployee] = useState<EmployeeData[]>([]);
    const [optionFilter, setOptionFilter] = useState<{ option: Estados }>({ option: estados.online });
    const { getUpdatesOnEmployees, loading } = useSetEmployes();
    const employesStore = useAppSelector(employesSelector);
    const { logo } = useAppSelector(getCurrentClientInfo);
    const { id } = useAppSelector(userSelectorData);

    useEffect(() => {
        const unsubscribe = getUpdatesOnEmployees();
        return () => {
            unsubscribe();
        };
    }, [getUpdatesOnEmployees]);

    useEffect(() => {
        if (employesStore.length > 0) {
            const { option } = optionFilter;
            setEmployee([...employesStore.filter((employee) => employee.estado === option && employee.id !== id)]);
        }
    }, [employesStore, id, optionFilter]);

    const handleSelectChanged = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setOptionFilter({
                ...optionFilter,
                [event.target.name]: event.target.value,
            });
        },
        [optionFilter]
    );

    const redirectToDiscord = useCallback((event: any) => {
        event.preventDefault();
        window.open('https://mail.google.com/chat/u/0/#chat/home', '_blank');
    }, []);

    return (
        <Card className="shadow-none m-0 p-0 TeamMembers h-100">
            <Row className="g-0 h-100 w-100" style={{ minHeight: '114px' }}>
                <Col xs={12} sm={3} md={2}>
                    <Card.Header
                        style={{ borderBottom: 'none' }}
                        className="d-flex flex-column gap-2 justify-content-center align-items-center h-100 p-2">
                        <h4 className="header-title mb-0 weik-text-grey-300">Equipo</h4>

                        <FormInput
                            name="option"
                            type="select"
                            className="form-select form-select-sm w-100"
                            key="option"
                            onChange={handleSelectChanged}>
                            <option>{estados.online}</option>
                            <option>{estados.offline}</option>
                        </FormInput>
                    </Card.Header>
                </Col>
                <Col xs={12} sm={9} md={10}>
                    <Card.Body className="p-0 m-0 border-start h-100">
                        {loading ? (
                            <SkeletonLoader />
                        ) : (
                            <SimpleBar className="m-0 p-2 h-100 me-2">
                                <div className="team-container h-100">
                                    {employee.map((member, index) => {
                                        return (
                                            <div className="team-card" key={index.toString()} style={{ flexShrink: 0 }}>
                                                <button
                                                    className="border-0 team-button p-0 m-0"
                                                    onClick={redirectToDiscord}>
                                                    <div className="member-image">
                                                        <img
                                                            className={classNames(
                                                                'img mt-1 rounded-circle object-fit-cover',
                                                                member.estado === estados.online
                                                                    ? 'member_image__online'
                                                                    : 'member_image__offline'
                                                            )}
                                                            style={{ objectFit: 'cover' }}
                                                            src={!!member.userImage ? member.userImage : logo}
                                                            width="40"
                                                            height="40"
                                                            alt="equipo"
                                                            loading="lazy"
                                                        />
                                                    </div>

                                                    <div className="member-text">
                                                        <h5 className="mt-0 mb-0 fw-semibold weik-text-grey-500 user-name">
                                                            {giveMeTheUserHeader(member)}
                                                        </h5>
                                                        <span className="weik-text-grey-500 user-role">
                                                            {member.role}
                                                        </span>
                                                    </div>
                                                </button>
                                            </div>
                                        );
                                    })}
                                </div>
                            </SimpleBar>
                        )}
                    </Card.Body>
                </Col>
            </Row>
        </Card>
    );
};

export default TeamMembers;
