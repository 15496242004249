import { EmployeeData } from '@models/user';

/**
 * truncate the string to a max number of characters and appends ‘…’
 */
export const truncateString = (str: string, maxLength: number): string => {
    if (str.length > maxLength) return str.substring(0, maxLength - 3) + '...';
    return str;
};

/**
 * Return the correct time for the time hour status
 */
export const giveMeCorrectTime = (time: string): string => {
    let newTime: string = time;
    if (newTime.includes('p') || newTime.includes('P')) {
        const timeArray: string[] = newTime.split(':');
        const hour: number = +timeArray.shift();
        newTime = (hour + 12).toString() + ':' + timeArray.join(':');
    }
    return newTime
        .replaceAll('a', '')
        .replaceAll('A', '')
        .replaceAll('p', '')
        .replaceAll('P', '')
        .replaceAll('m', '')
        .replaceAll('M', '')
        .replaceAll('.', '')
        .trim();
};

/**
 * Return the correct time for the time hour status for Dates
 */
export const giveMeCorrectTimeDisplayedForDate = (date: string): string => {
    if (date.includes(',')) {
        const copyDate: string = date;
        const dateArray = copyDate.split(',');
        const time: string = dateArray.pop();
        return (
            dateArray +
            ', ' +
            time.replaceAll('a', 'A').replaceAll('p', 'P').replaceAll(' m', 'M').replaceAll('.', '').trim()
        );
    } else return date;
};

/**
 * Return the User header in this order: username, name and email
 */
export const giveMeTheUserHeader = (user: EmployeeData): string => {
    if (user.userName !== '') return user.userName;
    else if (user.nombreCompleto !== '') return user.nombreCompleto;
    else return user.email;
};

/**
 * Return the User options taking in account first the whole name
 */
export const giveMeTheUserOptions = (user: EmployeeData): string => {
    if (user.nombreCompleto !== '') return user.nombreCompleto;
    else if (user.userName !== '') return user.userName;
    else return user.email;
};
