import { PrivateRouteProps } from '@models/routes/privateRoute';
import { DEFAULT_ROUTER_PATH } from 'constants/paths';
import { Navigate } from 'react-router-dom';
import { userSelector } from 'redux/selectores';
import { useAppSelector } from 'redux/store';

export const PrivateRoute = ({
    component: RouteComponent,
    roles,
    redirectTo = DEFAULT_ROUTER_PATH,
}: PrivateRouteProps) => {
    const { user, isUserLoggedIn } = useAppSelector(userSelector);

    if (isUserLoggedIn) {
        if (!roles.includes(user.role ?? '')) return <Navigate to={redirectTo} replace />;
    } else return <Navigate to={redirectTo} replace />;

    return <RouteComponent />;
};
