import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ResizeLeftSideMenu } from '@models/resize';
import { RESIZE_LEFT_MENU_NAME } from 'constants/redux';

const initialState: ResizeLeftSideMenu = { isExpanded: false, loading: false };

export const resizeSlice = createSlice({
    name: RESIZE_LEFT_MENU_NAME,
    initialState,
    reducers: {
        setIsLoading: (state: ResizeLeftSideMenu, action: PayloadAction<boolean>) => {
            const loading = action.payload;
            return { ...state, loading };
        },
        setIsExpanding: (state: ResizeLeftSideMenu, action: PayloadAction<boolean>) => {
            const isExpanded = action.payload;
            return { ...state, isExpanded };
        },
    },
});

export const { setIsExpanding, setIsLoading } = resizeSlice.actions;
export default resizeSlice.reducer;
