import { db } from 'database';
import { doc, setDoc } from 'firebase/firestore';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { userSelector } from 'redux/selectores';
import { toast } from 'react-toastify';
import { HorasTrabajoType } from '@models/user';
import { USERS_PATH } from 'constants/paths';
import { useDispatch } from 'react-redux';
import { updateUserWorkingHours } from 'redux/user';
import { giveMeCorrectTime } from 'utils/getCropedText';
import useRecordError from 'hooks/aws/useRecordError';
import { WEIK_CUSTOM_ERROR } from 'constants/aws';

export default function useCheckOutLaboral() {
    const [isSavingCheckOutLaboral, setIsSavingCheckOutLaboral] = useState<boolean>(false);
    const { user } = useAppSelector(userSelector);
    const { recordError } = useRecordError();
    const dispatch = useDispatch();

    const checkOutLaboral = useCallback(
        async (): Promise<void> => {
            setIsSavingCheckOutLaboral(true);
            try {
                const checkoutTime: Date = new Date();
                if (user.horasTrabajo?.length > 0) {
                    const todayDate = new Date().toLocaleDateString();
                    const horasTrabajo: HorasTrabajoType[] = user.horasTrabajo.map((horaTrabajo: HorasTrabajoType) => {
                        const { dia, checkIn } = horaTrabajo;
                        if (dia === todayDate) {
                            const diaDate: string = dia.split('/').reverse().join('/');
                            const checkOutDate: Date = new Date(
                                `${diaDate}, ${giveMeCorrectTime(checkoutTime.toLocaleTimeString('es'))}`
                            );
                            const checkInDate: Date = new Date(`${diaDate}, ${checkIn}`);
                            const horasDeTrabajoEnSegundos: number = (+checkOutDate - +checkInDate) / 1000;
                            return {
                                dia,
                                checkIn,
                                checkOut: giveMeCorrectTime(checkoutTime.toLocaleTimeString('es')),
                                horasDeTrabajo: Math.floor(horasDeTrabajoEnSegundos / 3600),
                                horasDeTrabajoEnSegundos,
                            };
                        } else {
                            return horaTrabajo;
                        }
                    });
                    const userRef = doc(db, USERS_PATH, user.id);
                    await setDoc(userRef, { horasTrabajo }, { merge: true });
                    dispatch(updateUserWorkingHours({ horasTrabajo }));
                    toast.success('Check-out Hecho');
                } else toast.error('No tiene horas de trabajo registradas');
            } catch (error) {
                recordError(WEIK_CUSTOM_ERROR  + "_checkOutLaboral", error);
                toast.error('Hubo un error intentando hacer Check-out');
            } finally {
                setIsSavingCheckOutLaboral(false);
            }
        },
        [dispatch, recordError, user.horasTrabajo, user.id]
    );

    return {
        isSavingCheckOutLaboral,
        checkOutLaboral,
    };
}
