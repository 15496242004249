import React from 'react';
import classNames from 'classnames';
import { SpinnerProps } from '../models/components/spinner';

const Spinner = ({ tag = 'div', type = 'bordered', className, color, size, children }: SpinnerProps) => {
    const Tag: React.ElementType = tag;
    return (
        <Tag
            role="status"
            className={classNames(
                {
                    'spinner-border': type === 'bordered',
                    'spinner-grow': type === 'grow',
                },
                color ? `text-${color}` : `text-secondary`,
                { [`avatar-${size}`]: size },
                className
            )}>
            {children}
        </Tag>
    );
};

export default Spinner;
