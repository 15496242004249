import { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es'; // the locale you want
import { DatepickerInputProps, HyperDatepickerProps } from '../models/components/datepicker';

registerLocale('es', es);

/* Datepicker with Input */
const DatepickerInput = forwardRef<HTMLInputElement, DatepickerInputProps>((props, ref) => {
    return (
        <input
            disabled={props.disabled}
            type="text"
            className={`form-control date cursor-text`}
            onClick={props.onClick}
            value={props.value}
            onChange={() => {}}
            ref={ref}
            name={props.name}
        />
    );
});

/* Datepicker with Addon Input */
const DatepickerInputWithAddon = forwardRef<HTMLInputElement, DatepickerInputProps>((props, ref) => (
    <div className="input-group" ref={ref}>
        <div className="input-group-append">
            <span className="input-group-text bg-primary border-primary text-white">
                <i className="mdi mdi-calendar-range font-13"></i>
            </span>
        </div>
        <input
            type="text"
            className="form-control form-control-light"
            onClick={props.onClick}
            value={props.value}
            name={props.name}
            readOnly
        />
    </div>
));

const HyperDatepicker = (props: HyperDatepickerProps) => {
    // handle custom input
    const input =
        (props.hideAddon || false) === true ? (
            <DatepickerInput name={props.name} inputClass={props.inputClass!} />
        ) : (
            <DatepickerInputWithAddon name={props.name} inputClass={props.inputClass!} />
        );

    return (
        <>
            {/* date picker control */}
            <DatePicker
                disabled={props.disabled ?? false}
                customInput={input}
                timeIntervals={props.tI}
                className={`form-control ${props.inputClass}`}
                selected={props.value}
                onChange={(date) => {
                    props.onChange(date!);
                }}
                showTimeSelect={props.showTimeSelect}
                timeFormat={props.timeFormat ?? 'hh:mm a'}
                timeCaption={props.timeCaption}
                dateFormat={props.dateFormat ?? 'dd/MM/yyyy'}
                minDate={props.minDate}
                maxDate={props.maxDate}
                monthsShown={props.monthsShown}
                showTimeSelectOnly={props.showTimeSelectOnly}
                inline={props.inline}
                autoComplete="off"
                showYearPicker={props.showYearPicker}
                showMonthYearPicker={props.showMonthPicker}
                name={props.name}
                popperPlacement={props.popperPlacement}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale={es}
            />
        </>
    );
};

export default HyperDatepicker;
