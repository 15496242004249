import { FilterProps } from '@models/components/filters';
import { roles } from 'constants/users';

const Filter = ({
    behavior = roles.admin,
    type = 'ppts',
    padding = '',
    pointerClass = '',
    isDisabled = false,
    badgeClass = '',
    isRegister = false,
    showLabel = true,
    isFilter = true,
    opts = {},
    name = '',
    labelUp = false,
    label = '',
    options,
    optionsObj,
    isDisabledAlt = false,
    changeGroupFunction = () => {},
    blurGroupFunction = () => {},
}: FilterProps) => {
    return (
        <div style={{ pointerEvents: isDisabledAlt ? 'none' : 'all' }} className="col-12 col-sm-6 col-xl-auto m-0">
            <div className={`${!labelUp ? 'd-flex' : 'd-block'} align-items-center`}>
                {showLabel && (
                    <label htmlFor="status-select" className={`me-2 ${labelUp && 'form-label weik-text-grey-300'}`}>
                        {label}
                    </label>
                )}
                <select
                    disabled={isDisabled}
                    className={`form-select ${isRegister && 'py-1 ps-2' && badgeClass} ${pointerClass} ${padding}`}
                    id="status-select"
                    name={name}
                    onChange={(e) => changeGroupFunction(e.target, type, behavior)}
                    onBlur={(e) => blurGroupFunction(e)}
                    {...opts}>
                    {isFilter && <option value="all">Todos</option>}

                    {optionsObj
                        ? optionsObj.map((option, index) => {
                              return (
                                  <option
                                      disabled={option.isDisabled}
                                      key={index}
                                      value={option.value.toLowerCase()}
                                      className={'optionSelect'}>
                                      {option.value}
                                  </option>
                              );
                          })
                        : options.map((option, index) => {
                              return (
                                  <option key={index} value={option.toLowerCase()} className={'optionSelect'}>
                                      {option}
                                  </option>
                              );
                          })}
                </select>
            </div>
        </div>
    );
};

export default Filter;
