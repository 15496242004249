import { db } from 'database';
import { doc, setDoc } from 'firebase/firestore';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { userSelector } from 'redux/selectores';
import { toast } from 'react-toastify';
import { HorasExtraType } from '@models/user';
import { USERS_PATH } from 'constants/paths';
import { useDispatch } from 'react-redux';
import { updateUserExtraHours } from 'redux/user';
import { giveMeCorrectTime } from 'utils/getCropedText';
import useRecordError from 'hooks/aws/useRecordError';
import { WEIK_CUSTOM_ERROR } from 'constants/aws';

export default function useCheckInExtras() {
    const [isSavingCheckInExtras, setIsSavingCheckInExtras] = useState<boolean>(false);
    const { user } = useAppSelector(userSelector);
    const { recordError } = useRecordError();
    const dispatch = useDispatch();

    const checkInExtra = useCallback(
        async (): Promise<void> => {
            setIsSavingCheckInExtras(true);
            try {
                let horasExtra: HorasExtraType[] = [];
                const checkoutExtraTime: Date = new Date();
                checkoutExtraTime.setHours(23);
                checkoutExtraTime.setMinutes(59);
                checkoutExtraTime.setSeconds(0);
                if (user.horasExtra.length > 0) {
                    let theDayExist: boolean = false;
                    const todayDate = new Date().toLocaleDateString();
                    horasExtra = user.horasExtra.map((horaExtra: HorasExtraType) => {
                        const { dia } = horaExtra;
                        if (dia === todayDate) {
                            theDayExist = true;
                            return {
                                dia,
                                checkInExtra: giveMeCorrectTime(new Date().toLocaleTimeString('es')),
                                checkOutExtra: giveMeCorrectTime(checkoutExtraTime.toLocaleTimeString('es')),
                                horasDeTrabajoExtra: 0,
                                horasDeTrabajoExtraEnSegundos: 0,
                            };
                        } else {
                            return horaExtra;
                        }
                    });
                    if (!theDayExist) {
                        horasExtra = [
                            ...user.horasExtra,
                            {
                                dia: new Date().toLocaleDateString(),
                                checkInExtra: giveMeCorrectTime(new Date().toLocaleTimeString('es')),
                                checkOutExtra: giveMeCorrectTime(checkoutExtraTime.toLocaleTimeString('es')),
                                horasDeTrabajoExtra: 0,
                                horasDeTrabajoExtraEnSegundos: 0,
                            },
                        ];
                    }
                } else {
                    horasExtra = [
                        {
                            dia: new Date().toLocaleDateString(),
                            checkInExtra: giveMeCorrectTime(new Date().toLocaleTimeString('es')),
                            checkOutExtra: giveMeCorrectTime(checkoutExtraTime.toLocaleTimeString('es')),
                            horasDeTrabajoExtra: 0,
                            horasDeTrabajoExtraEnSegundos: 0,
                        },
                    ];
                }
                const userRef = doc(db, USERS_PATH, user.id);
                await setDoc(userRef, { horasExtra }, { merge: true });
                dispatch(updateUserExtraHours({ horasExtra }));
                toast.success('Check-in de horas extras Hecho');
            } catch (error) {
                recordError(WEIK_CUSTOM_ERROR + "_checkInExtra", error);
                toast.error('Hubo un error intentando hacer Check-in de horas extras');
            } finally {
                setIsSavingCheckInExtras(false);
            }
        },
        [dispatch, recordError, user.horasExtra, user.id]
    );

    return {
        isSavingCheckInExtras,
        checkInExtra,
    };
}
