import {
    ACCOUNT_ROUTE,
    LOGIN_ROUTE,
    FORGET_PASSWORD_ROUTE,
    DESIGNERS_ROUTE,
    CALENDARIO_ROUTE,
    SOLICITUDES_ROUTE,
    NOTICIAS_ROUTE,
    PLATAFORMA_ROUTE,
    AGREGAR_CLIENTE_ROUTE,
    PPTS_ROUTE,
    MOTIONS_ROUTE,
    REPORTES_PPTS_ROUTE,
    REPORTES_MOTIONS_ROUTE,
    NOT_FOUND_ROUTE,
    CLIENTS_ID_ROUTE,
} from 'constants/routes';
import { lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import { DefaultLayout, DetachedLayout } from 'layouts';
import { LoadComponent } from './LoadComponent';
import { PrivateRoute } from './PrivateRoute';
import { roles, weikUsers } from 'constants/users';
import { WEIKSTUDIO_DOMAIN } from 'constants/domains';
import Root from './Root';

// account
const Login = lazy(() => import('pages/account/Login'));
const ForgetPassword = lazy(() => import('pages/account/ForgetPassword'));

// For clients
const HomeClients = lazy(() => import('pages/Clients/home'));
const ProjectsPptsClient = lazy(() => import('pages/Clients/projects/ppts'));
const ProjectsMotionsClient = lazy(() => import('pages/Clients/projects/motions'));
const ReportsPptsClients = lazy(() => import('pages/Clients/reportes/ppts'));
const ReportsMotionsClients = lazy(() => import('pages/Clients/reportes/motions'));

// For Admins
const HomeAdmins = lazy(() => import('pages/Admins/home'));
const Solicitudes = lazy(() => import('pages/Admins/Solicitudes'));
const GestorNoticias = lazy(() => import('pages/Admins/noticias'));
const Plataforma = lazy(() => import('pages/Admins/plataforma'));
const ClientesYUsuarios = lazy(() => import('pages/Admins/ClientesUsuarios'));
const ProjectsPptsAdmins = lazy(() => import('pages/Admins/projects/ppts'));
const ProjectsMotionsAdmins = lazy(() => import('pages/Admins/projects/motions'));
const ReportsPptsAdmins = lazy(() => import('pages/Admins/reportes/ppts'));
const ReportsMotionsAdmins = lazy(() => import('pages/Admins/reportes/motions'));

// For Designers
const HomeDesigners = lazy(() => import('pages/Designer/Home'));
const CalendarioDesigners = lazy(() => import('pages/Designer/Calendario'));

// error
const ErrorPageNotFound = lazy(() => import('pages/error/PageNotFound'));

const AllRoutes = () => {
    return useRoutes([
        { index: true, element: <Root /> },
        {
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: ACCOUNT_ROUTE,
                    children: [
                        { path: LOGIN_ROUTE, element: <LoadComponent component={Login} /> },
                        { path: FORGET_PASSWORD_ROUTE, element: <LoadComponent component={ForgetPassword} /> },
                    ],
                },
            ],
        },
        {
            path: '/',
            element: <PrivateRoute roles={weikUsers} component={DetachedLayout} />,
            children: [
                {
                    path: WEIKSTUDIO_DOMAIN,
                    children: [
                        {
                            index: true,
                            element: <LoadComponent roles={[roles.admin]} component={HomeAdmins} />,
                        },
                        {
                            path: DESIGNERS_ROUTE,
                            element: <LoadComponent roles={[roles.diseñador]} component={HomeDesigners} />,
                        },
                        {
                            path: CALENDARIO_ROUTE,
                            element: <LoadComponent roles={[roles.diseñador]} component={CalendarioDesigners} />,
                        },
                        {
                            path: SOLICITUDES_ROUTE,
                            element: <LoadComponent roles={[roles.admin]} component={Solicitudes} />,
                        },
                        {
                            path: NOTICIAS_ROUTE,
                            element: <LoadComponent roles={[roles.admin]} component={GestorNoticias} />,
                        },
                        {
                            path: PLATAFORMA_ROUTE,
                            element: <LoadComponent roles={[roles.admin]} component={Plataforma} />,
                        },
                        {
                            path: AGREGAR_CLIENTE_ROUTE,
                            element: <LoadComponent roles={[roles.admin]} component={ClientesYUsuarios} />,
                        },
                        {
                            path: CLIENTS_ID_ROUTE,
                            children: [
                                {
                                    path: PPTS_ROUTE,
                                    element: <LoadComponent roles={[roles.admin]} component={ProjectsPptsAdmins} />,
                                },
                                {
                                    path: MOTIONS_ROUTE,
                                    element: <LoadComponent roles={[roles.admin]} component={ProjectsMotionsAdmins} />,
                                },
                                {
                                    path: REPORTES_PPTS_ROUTE,
                                    element: <LoadComponent roles={[roles.admin]} component={ReportsPptsAdmins} />,
                                },
                                {
                                    path: REPORTES_MOTIONS_ROUTE,
                                    element: <LoadComponent roles={[roles.admin]} component={ReportsMotionsAdmins} />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            path: '/',
            element: <PrivateRoute roles={[roles.client]} component={DetachedLayout} />,
            children: [
                {
                    path: CLIENTS_ID_ROUTE,
                    element: <LoadComponent component={HomeClients} />,
                },
                {
                    path: CLIENTS_ID_ROUTE,
                    children: [
                        { path: PPTS_ROUTE, element: <LoadComponent component={ProjectsPptsClient} /> },
                        { path: MOTIONS_ROUTE, element: <LoadComponent component={ProjectsMotionsClient} /> },
                        { path: REPORTES_PPTS_ROUTE, element: <LoadComponent component={ReportsPptsClients} /> },
                        { path: REPORTES_MOTIONS_ROUTE, element: <LoadComponent component={ReportsMotionsClients} /> },
                    ],
                },
            ],
        },
        { path: NOT_FOUND_ROUTE, element: <LoadComponent component={ErrorPageNotFound} /> },
        { path: '*', element: <Navigate to={NOT_FOUND_ROUTE} /> },
    ]);
};

export { AllRoutes };
