import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import useFileUploader from './useFileUploader';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FileUploaderProps } from '@models/components/fileUploader';

const FileUploader = ({
    showPreview = true,
    onFileUpload,
    className,
    showText = true,
    disabled = false,
    isResetingFiles = false,
}: FileUploaderProps) => {
    const { selectedFiles, handleAcceptedFiles, removeFile, resetFiles } = useFileUploader(showPreview);

    useEffect(() => {
        if (isResetingFiles) resetFiles();
    }, [isResetingFiles, resetFiles]);

    const isImageOrGif = useCallback((file: File) => {
        const isValid = file.type.startsWith('image/') || file.name.toLowerCase().endsWith('.gif');
        if (!isValid) {
            toast.warning('Solo se pueden agregar imágenes o gifs');
        }
        return isValid;
    }, []);

    return (
        <>
            <Dropzone
                disabled={disabled}
                onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles.filter(isImageOrGif), onFileUpload)}>
                {({ getRootProps, getInputProps }) => (
                    <div className={`dropzone mb-2 ${className}`}>
                        <div className="dz-message needsclick" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <i className="h3 text-muted weik-text-grey-300 mdi mdi-cloud-upload"></i>
                            {showText && (
                                <>
                                    <h5 className="weik-text-grey-300">
                                        Suelte los archivos aquí o haga clic para cargar.
                                    </h5>
                                    <span className="text-muted weik-text-grey-300 font-13">
                                        Solo carga imágenes o GIFs
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </Dropzone>

            {showPreview && selectedFiles.length > 0 && (
                <div className="dropzone-previews mt-1 mb-2 w-100" id="uploadPreviewTemplate">
                    {(selectedFiles || []).map((f) => {
                        return (
                            <Card className="mt-1 mb-0 shadow-none border" key={f.preview}>
                                <div className="p-2">
                                    <Row className="align-items-center">
                                        {f.preview && (
                                            <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    className="avatar-sm rounded bg-light object-fit-cover"
                                                    alt={f.name}
                                                    src={f.preview}
                                                    loading="lazy"
                                                />
                                            </Col>
                                        )}
                                        {!f.preview && (
                                            <Col className="col-auto">
                                                <div className="avatar-sm">
                                                    <span className="avatar-title bg-primary rounded">
                                                        {f.type.split('/')[0]}
                                                    </span>
                                                </div>
                                            </Col>
                                        )}
                                        <Col className="ps-0">
                                            <Link to="#" className="text-muted weik-text-grey-300 fw-bold">
                                                {f.name}
                                            </Link>
                                            <p className="mb-0 weik-text-grey-300">
                                                <strong className="weik-text-grey-500">{f.formattedSize}</strong>
                                            </p>
                                        </Col>
                                        <Col className="text-end">
                                            <Link
                                                to="#"
                                                className="btn btn-link btn-lg text-muted weik-text-grey-300 shadow-none">
                                                <i className="mdi mdi-close" onClick={() => removeFile(f)}></i>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export { FileUploader };
